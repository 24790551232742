<template>
  <div class="bankaccount template-1">
    <page-body class="mx-lg">
      <section class="section-1">
        <header class="page-header container">
          <h1>{{ translations.tcBankAccount }}</h1>
        </header>
        <div v-if="!allowPage">
          <security-banner :i18n="translations.components"></security-banner>
        </div>
        <div v-if="allowPage">
          <b-container>
            <b-row>
              <b-col sm="5">
                <b-container v-if="!allowEdit">
                  <b-row style="line-height: 34px">
                    <b-col>
                      <b>{{ campLabel }}</b>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <label>
                        <b>{{ translations.tcAccountType }}:</b> &nbsp;
                      </label>
                      {{ translatedAccountType }}
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <label>
                        <b>{{ translations.tcBankRoutingNumber }}:</b> &nbsp;
                      </label>
                      {{ bankAccount.bnk_routing_number }}
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <label>
                        <b>{{ translations.tcBankAccountNumber }}:</b> &nbsp;
                      </label>
                      {{ bankAccount.bnk_account_number }}
                    </b-col>
                  </b-row>
                </b-container>
                <b-container v-if="allowEdit">
                  <b-row>
                    <b-col>
                      <b-form-group :label="translations.tcAccountType">
                        <b-form-radio v-model="bankAccount.bnk_bkt_key" name="accountType"
                          value="28f00c92-b2ef-49c2-82a2-937478c1750e">
                          {{ translations.tcChecking }}
                        </b-form-radio>
                        <b-form-radio v-model="bankAccount.bnk_bkt_key" name="accountType"
                          value="c2cb43d9-4118-495b-9fdb-31f2a211ce2c">
                          {{ translations.tcSavings }}
                        </b-form-radio>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <label for="arouting">{{ translations.tcBankRoutingNumber }}</label>
                      <b-input id="arouting" @keypress="onlyNumber($event)" v-model="bankAccount.bnk_routing_number">
                      </b-input>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <label for="vrouting">{{ translations.tcVerifyRoutingNumber }}</label>
                      <b-input id="vrouting" @keypress="onlyNumber($event)" v-model="verify.routing"></b-input>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <label for="arouting">{{ translations.tcBankAccountNumber }}</label>
                      <b-input id="anumber" @keypress="onlyNumber($event)" v-model="bankAccount.bnk_account_number">
                      </b-input>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <label for="arouting">{{ translations.tcVerifyAccountNumber }}</label>
                      <b-input id="vnumber" @keypress="onlyNumber($event)" v-model="verify.number"></b-input>
                    </b-col>
                  </b-row>
                </b-container>
                <b-container>
                  <b-row>
                    <b-col>
                      <div class="buttonsMove">
                        <b-button v-if="!allowEdit" class="btn btn-tertiary btn-w-med ml-4 float-right"
                          @click="handleEditClick">
                          {{ translations.tcEdit }} </b-button>&nbsp;
                        <b-button v-if="allowEdit" :disabled="!validateInput()"
                          class="btn btn-tertiary btn-w-med ml-4 float-right" @click="handleSaveClick()">
                          {{ translations.tcSave }} </b-button>&nbsp;
                        <b-button class="btn btn-tertiary btn-w-med ml-4 float-right" @click="handleCancelClick">
                          {{ translations.tcCancel }}
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
              <b-col sm="6">
                <div class="container-fluid container-check-info">
                  <img alt="sample check" src="@/assets/checkRoutingNoTextGray.gif" />
                  <div class='d-flex justify-content-around check-number-container'>
                    <p>{{ translations.tcBankRoutingNumber }}</p>
                    <p>{{ translations.tcBankAccountNumber }}</p>
                    <p>{{ translations.tcCheckNumber }}</p>
                  </div>
                  <div class="err mt-4" v-if="allowEdit && validateInput() === false">
                    {{ errorMessage.a }}
                    <br />
                    {{ errorMessage.b }}
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </section>
    </page-body>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex'
import { numericMixin } from '@/mixins/numericMixin'
import pageBody from '@/components/page-components/PageBody.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { staffUserMixin } from '@/mixins/staffUserMixin'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'bank-account',
  props: {},
  mixins: [numericMixin, staffUserMixin, translationMixin],
  data() {
    return {
      allowEdit: false,
      bankAccount: {},
      errorMessage: {
        a: 'The account and routing numbers must be entered into the verify boxes and must match, exactly.',
        b: 'The account and routing fields must contain only numbers. No asterisks (*) or special characters will be accepted.',
      },
      noCloseOnBackdrop: true,
      secured_tile_view_controls: {
        treasury_state_bank_account_tile: '3e97e4b9-c4cb-4686-8923-a241e582938e',
        treasury_camp_bank_account_tile: '7d18deed-9f6b-4bcf-9680-7b0837eae8b7',
      },
      translatedAccountType: '',
      translations: {},
      verify: {
        routing: null,
        number: null,
      },
    }
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('security-banner')
      ]).then((results) => {
        const componentTranslations = results[1]
        this.$set(this.translations, 'components', componentTranslations)
        this.errorMessage.a = this.translations.tcErrorMsgA
        this.errorMessage.b = this.translations.tcErrorMsgB
      }
      )
      if (this.allowPage) {
        await this.getAccount(this.stateOrCamp === 's' ? this.stateUndist.cmp_org_key : this.currentCamp.key).then(() => {
          if (!!this.account.bnk_account_type) {
            const lookup = this.convertValForTranslation(this.account.bnk_account_type) // `tcBusinessChecking`
            this.translatedAccountType = this.translations.hasOwnProperty(lookup) ?
              this.translations[lookup] :
              this.account.bnk_account_type
            this.bankAccount = this.account
          }
        })
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  computed: {
    ...mapGetters({
      account: 'accountModule/currentAccount',
      currentCamp: 'campModule/currentCamp',
      iCanSee: 'user/iCanSee',
      prefCulture: 'user/userPreferredCulture',
      stateOrCamp: 'campModule/stateOrCamp',
      stateUndist: 'campModule/stateUndist',
    }),
    allowPage() {
      return (
        (this.stateOrCamp === 's'
          ? this.iCanSee(this.secured_tile_view_controls.treasury_state_bank_account_tile)
          : this.iCanSee(this.secured_tile_view_controls.treasury_camp_bank_account_tile)) &&
        ((!!this.stateUndist && !!this.stateUndist.cmp_org_key) || this.isStaffMember)
      )
    },
    campLabel() {
      return this.stateOrCamp === 's'
        ? `${this.stateUndist.camp_name} Camp - ${this.stateUndist.camp_number}`
        : `${this.currentCamp.text} Camp - ${this.currentCamp.value}`
    },
  },
  methods: {
    ...mapActions({
      getAccount: 'accountModule/getAccount',
      setLoadingStatus: 'menu/setLoadingStatus',
      update: 'accountModule/updateAccount',
    }),
    handleCancelClick() {
      this.allowEdit = false
      this.$router.push({ path: `/officers/camp/treasury-tools/${this.stateOrCamp}` })
    },
    handleEditClick() {
      this.allowEdit = true
    },
    async handleSaveClick() {
      const acctData = {
        account: this.bankAccount.bnk_account_number,
        routing: this.bankAccount.bnk_routing_number,
        type: this.bankAccount.bnk_bkt_key,
        stateOrCamp: this.stateOrCamp
      }
      if (this.validateInput()) {
        await this.update(acctData).then(() => {
          this.getAccount(this.stateOrCamp === 's' ? this.stateUndist.cmp_org_key : this.currentCamp.key)
          this.verify = { routing: null, number: null }
          this.allowEdit = false
        })
      }
    },
    // async
    handleFormSubmit() { },
    validateInput() {
      return (
        !!this.bankAccount.bnk_account_number &&
        !!this.bankAccount.bnk_routing_number &&
        !!this.bankAccount.bnk_account_type &&
        this.bankAccount.bnk_routing_number === this.verify.routing &&
        this.bankAccount.bnk_account_number === this.verify.number &&
        !this.bankAccount.bnk_account_number.includes('*')
      )
    },
  },
  components: {
    pageBody: pageBody,
    securityBanner: SecurityBanner,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';

.buttonsMove {
  float: left;
  margin-top: 30px;
}

.template-1 .page-header {
  padding-top: 0 !important;
}

.flex {
  display: flex;
}

.dollarsign {
  position: relative;
  height: 0;
  left: -14px;
}

.nonew {
  padding: 30px;
}

.dtext,
.shorttext,
.mediumtext {
  line-height: 13px;
  font-size: 12px;
}

.rtext {
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  margin-top: 10px;
}

.shorttext {
  margin-top: 8px;
}

.mediumtext {
  margin-top: 4px;
}

.brow {
  line-height: 36px;
}

.btn.disabled {
  cursor: not-allowed;
}

.check-number-container {
  max-width: 600px;
}

.editing {
  background-color: #35b130;
  color: #ffffff;
  border-radius: 6px;
}

.subtitle {
  margin: 10px 0;
}

.bankaccount {
  .page-body {
    margin-top: 60px;

    h2 {
      margin-bottom: 36px;
    }
  }

  .section-1,
  .section-2 {
    margin-bottom: 52px;

    >.row {
      margin-bottom: 40px;
    }

    .t-container {
      @include breakpoint(sm) {
        margin-bottom: 1rem;
      }
    }

    .col {
      @include breakpoint(sm) {
        padding-left: 15px !important;
        padding-right: 15px !important;
      }

      &:first-of-type {
        padding-right: 23px;
      }

      &:last-of-type {
        padding-left: 23px;

        .number-card:last-of-type {
          margin-bottom: 0;
        }
      }

      table {
        width: 100% !important;
      }
    }
  }

  .section-3 {
    margin-bottom: 60px;

    .col {
      &:nth-child(2n-1) {
        padding-right: 23px;
      }

      &:nth-child(2n) {
        padding-left: 23px;
      }
    }

    @include breakpoint(sm) {
      .col {
        height: 100%;
        max-width: none;
        width: 100%;
        flex: 0 0 100%;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

img {
  width: 100%;
}
</style>
